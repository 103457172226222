<template>
   <container id="about" links="true" :title="t('title')" :subtitle="t('subtitle')">
       <div class="about">
            <!-- <div class="about__side">
                <img src="@/assets/img/sebastien.webp" width="350" height="455" alt="sebastien goldberg web development">
                <div class="about__side--text">
                    <div class="about__side--name">
                        <h4>
                            Sébastien Goldberg
                        </h4>
                        <h5>
                            {{t('role')}}
                        </h5>
                    </div>
                    <div class="about__side--in">
                        <a href="https://www.linkedin.com/in/sebastiengoldberg/" rel="noopener" aria-label="linked in profile sebastien goldberg" target="_blank">
                            <Svg icon="linkedin" className="about__side--svg"/>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="about__content">
                <div v-for="item in content" :key="item.title" class="about__content--section">
                     <h4>
                        {{item.title}}
                    </h4>
                    <template v-for="(t, j) in item.content" :key="j">
                        <p v-if="t.type === 'p'">
                            {{t.content}}
                        </p>
                        <ul v-else-if="t.type === 'ul'">
                            <li v-for="l in t.content">{{l}}</li>
                        </ul>
                    </template>
                </div>
            </div>
       </div>
    
  </container>
</template>

<script>
import {useI18n} from 'vue-i18n';
import { computed } from 'vue';

import Container from './Container'
import Svg from './utils/Svg';

export default {
    components:{
        Container,
        Svg
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('../locales/en/about.json'),
              fr: require('../locales/fr/about.json')
            }
        })
        const content = computed(() =>[
            {
                title: t('content[0].title'),
                content: [
                    {
                        type: "p",
                        content: t('content[0].text[0]')
                    },
                    {
                        type: "p",
                        content: t('content[0].text[1]')
                    },
                    {
                        type: "p",
                        content: t('content[0].text[2]')
                    }
                ]
            },
            {
                title: t('content[1].title'),
                content: [
                    {
                        type: "p",
                        content: t('content[1].text[0]')
                    },
                    {
                        type: "p",
                        content: t('content[1].text[1]')
                    },
                    {
                        type: "p",
                        content: t('content[1].text[2]')
                    }
                ]
            },
            {
                title: t('content[2].title'),
                content: [
                    {
                        type: "p",
                        content: t('content[2].text')
                    },
                    {
                        type: "ul",
                        content: [
                            t('content[2].values[0]'),
                            t('content[2].values[1]'),
                            t('content[2].values[2]'),
                            t('content[2].values[3]'),
                            t('content[2].values[4]')
                        ]
                    }
                ]
            }
        ]);
        return{
            content,
            t
        }
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.about{
    display: flex;
    width: 100%;

    @include respond(phone){
        flex-direction: column;
    }
    &__side{
        flex: 0 0 30%;
        margin-right: 2rem;

        @include respond(phone){
            margin-right: 0;
            margin-bottom: 4rem;
        }

        & img{
            width: 100%;
            height: auto;
        }

        &--text{
            margin-top: .8rem;
            display: flex;
        }

        &--name{
            & h4{
                text-transform: uppercase;
                letter-spacing: .1rem;
                font-size: 1.8rem;
                margin-bottom: .3rem;
            }

            & h5{
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: .1rem;
            }
        }

        &--in{
            margin-left: auto;

            & a{
                color: inherit;
            }
        }

        &--svg{
            @include svg(2.2rem);
        }
    }
    &__content{
        flex: 1;

        &--section{
            & h4{
                margin-bottom: 1rem;
                text-transform: uppercase;
                font-size: 1.8rem;
                letter-spacing: .2rem;
            }

            & p{
                margin-bottom: .6rem;
                font-size: 1.4rem;
                text-align: justify;
            }

            &:not(:last-child){
                margin-bottom: 2.4rem;
            }

            & ul{
                display: flex;
                flex-wrap: wrap;
                margin: 2rem 0;
            }

            & li{
                list-style: none;
                padding: .8rem 1.5rem;
                border: 2px solid currentColor;
                color:$color-blue-main;
                text-transform: uppercase;
                letter-spacing: .1rem;
                font-weight: 700;
                margin-bottom: 1rem;
                box-shadow: .3rem .3rem 0rem #ccc;

                 @include respond(phone){
                    padding: .6rem 1.2rem;
                }

                &:not(:last-child){
                    margin-right: 1rem;
                }
            }
        }
    }
}

</style>